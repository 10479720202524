import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Atelier = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Atelier") || (!isInglish && "Cultura del Atelier")}>
                <div className='seccion'>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/atelier_seccion/little_creek_school_atelier_1.jpg" alt="atelier" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>The Atelier</>) || (!isInglish && <>Cultura del Atelier</>)}</h2>
                            {(isInglish && 
                            <p> 
                               At Little Creek, we believe that all children are naturally creative and should have opportunities to develop their creative and expressive abilities. Our school is seen as a Big Atelier, where children have spaces to imagine, experiment, create, and express themselves through various media, materials, and artistic languages.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                En Little Creek, creemos que todos los niños son naturalmente creativos y deben tener oportunidades para desarrollar sus habilidades creativas y expresivas. Nuestra escuela es vista como un gran Atelier, donde los niños tienen espacios para imaginar, experimentar, crear y expresarse a través de diversos medios, materiales y lenguajes artísticos.
                            </p>)}
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                <span className='color-experiencia'>We actively promote experiences in music, dance, visual arts, and theater, providing young learners with opportunities to explore different forms of creative expression. </span><br />
                                This approach fosters creativity and flexible thinking, helping children connect abstract concepts with the tangible world around them.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-experiencia'>Promovemos activamente experiencias en música, danza, artes visuales y teatro, brindando a los niños aprendizajes oportunidades para explorar diferentes formas de expresión creativa. </span><br />
                                Este enfoque fomenta la creatividad y el pensamiento flexible, ayudando a los niños a conectar conceptos abstractos con el mundo tangible que los rodea.
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/atelier_seccion/little_creek_school_atelier_2.jpg" alt="atelier" />
                        </div>
                    </div>

                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Atelier;
