import React from 'react';
import "./footer.css";
import { useContext } from 'react';
import { IdiomaContext } from "../../context/IdiomaContext";
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    const {isInglish} = useContext(IdiomaContext);

    return (
        <>
            <div className='footer flex column OoS'>
                <div className='contDatosFooter flex'>
                    <img className='stickerFooter' src="/images/pajaro.png" alt="pajaro" />
                    <div className='datosFooter flex column'>
                        <p>Easy Creek Lane </p>
                        <p>Coos Bay, OR 97420 </p>
                        <p>info@littlecreekschool.com </p>
                        <p>(541) 269 - 1230 </p>
                        <div className='contfooterLogosRedes flex'> 
                            <a href="https://instagram.com/littlecreekschool?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noreferrer">
                                <img className='footerLogosRedes' src="/images/instagram_logo.png" alt="facebook logo" />
                            </a>
                            <a href="https://www.facebook.com/people/Little-Creek-Bilingual-School/100092339123681/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                                <img className='footerLogosRedes' src="/images/facebook_logo.png" alt="facebook logo" />
                            </a>
                        </div>
                        <HashLink to="/admin/proceso#top" smooth={true}><button className='botonExperiencia boton-footer'>{(isInglish && "Enrollment") || (!isInglish && "Inscripción")}</button></HashLink>
                    </div>
                    {/* <div className='contMapaFooter'>
                        <iframe className='mapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.7840515285634!2d-124.20508718430118!3d43.23498708739021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54c47e3f4bf36787%3A0x651fd8d346b2d4e!2s93507%20Easy%20Creek%20Ln%2C%20Coos%20Bay%2C%20OR%2097420%2C%20USA!5e0!3m2!1sen!2sar!4v1679151269460!5m2!1sen!2sar" title="map"></iframe>
                    </div> */}
                </div>
                <div className='contDerechosFooter flex'>
                    <h2>&copy; 2023 Little Creek School</h2>
                </div>
            </div>
        </>
    );
}

export default Footer;
