import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Curriculo = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Emergent curriculum") || (!isInglish && "Currículo emergente")}>
                <div className='seccion'>
                  
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opc' src="/images/curriculum_seccion/little_creek_school_curriculum_1.jpg" alt="curriculum" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Holistic and emergent curriculum</>) || (!isInglish && <>Currículo holístico y emergente</>)}</h2>
                            {(isInglish && 
                            <p> 
                                Our school curriculum is emergent and flexible, driven by children's questions and ideas, which serve as the foundation for their learning journey. <br />
                                <span className='color-experiencia'> We promote experiential, child-initiated learning through play-based experiences in the natural world, fostering individualized growth. </span> <br />
                                By balancing child- and teacher-directed activities, we provide extended periods of uninterrupted play and numerous hands-on experiences that nurture creativity and curiosity.<br />
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Nuestro currículo es emergente y flexible, guiado por las preguntas e ideas de los niños, que sirven como base para su viaje de aprendizaje. <br />
                                <span className='color-experiencia'> Promovemos un aprendizaje experiencial e iniciado por los niños a través de experiencias de juego en el mundo natural, fomentando el crecimiento individualizado. </span> <br />
                                Al equilibrar actividades dirigidas por los niños y por los maestros, ofrecemos períodos prolongados de juego ininterrumpido y numerosas experiencias prácticas que nutren la creatividad y la curiosidad. <br />
                            </p>)}
                        </div>
                    </div>  

                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                Following the natural rhythm of the seasons, our holistic curriculum guides children in exploring themselves and the world around them. Topics are integrated across reading, mathematics, science, social studies, fine arts, and movement, with a focus on eco-literacy and mindfulness. <br />
                                This approach helps children develop critical thinking skills, solve real-world problems, and gain confidence in their identity, purpose, and place in the world.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Siguiendo el ritmo natural de las estaciones, nuestro currículo holístico guía a los niños en la exploración de sí mismos y del mundo que los rodea. Los temas se integran a través de la lectura, las matemáticas, las ciencias, los estudios sociales, las artes plásticas y el movimiento, con un enfoque en la ecoalfabetización y la conciencia plena. <br /> 
                                Este enfoque ayuda a los niños a desarrollar habilidades de pensamiento crítico, resolver problemas del mundo real y ganar confianza en su identidad, propósito y lugar en el mundo.
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/curriculum_seccion/little_creek_school_curriculum_2.jpg" alt="curriculum" />
                        </div>
                    </div>  
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Curriculo;
