import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import "../../experiencia/experiencia.css";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Comunidad = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our program") || (!isInglish && "Nuestro programa")} subsecciontitle={(isInglish && "School Community") || (!isInglish && "Comunidad Educatica")}>
                <div className='seccion'>
                   
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>School Community</>) || (!isInglish && <>Comunidad educativa</>)}</h2>
                            {(isInglish && 
                            <p> 
                                <span className='color-programa'>At Little Creek, we believe that community is a fundamental component of our practices. </span> <br />
                                We aim to help children build a sense of belonging and understand that they are part of both a local and global community. This connectivity and social interaction teach essential values such as collaboration, diversity, and empathy.<br/>
                                
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                <span className='color-programa'>En Little Creek, creemos que la comunidad es un componente fundamental de nuestras prácticas. </span> <br />
                                Nuestro objetivo es ayudar a los niños a desarrollar un sentido de pertenencia y comprender que forman parte de una comunidad local y global. Esta conectividad y la interacción social enseñan valores esenciales como la colaboración, la diversidad y la empatía.<br/>
                                
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl ofcon' src="/images/community_seccion/little_creek_school_community_11.jpg" alt="community" />
                        </div>
                    </div>
                   
                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opl' src="/images/community_seccion/little_creek_school_community_2.jpg" alt="community" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                We greatly value the active participation of families in our educational community, as it ensures meaningful learning experiences. Families and community members are encouraged to contribute their skills to learning projects, spaces, and other areas where they feel they can make an impact. Our doors are always open.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Valoramos enormemente la participación activa de las familias en nuestra comunidad educativa, ya que asegura experiencias de aprendizaje significativas. Se alienta a las familias y a los miembros de la comunidad a contribuir con sus habilidades en proyectos de aprendizaje, espacios y otras áreas en las que sientan que pueden tener un impacto. Nuestras puertas siempre están abiertas.
                            </p>)}
                        </div>
                    </div>                
                  
                </div>
            </div>   
            <EndNavBar/>
        </>
    );
}

export default Comunidad;
