import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import "./grupos.css";

const Grupos = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return (
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "Our Program") || (!isInglish && "Nuestro Programa")} subsecciontitle={(isInglish && "Multi-age groups") || (!isInglish && "Grupos multiedad")}>
                <div className='seccion'>
                    {(isInglish && <h1 className='tituloExp'>Our program</h1>) || (!isInglish  && <h1 className='tituloExp'>Nuestro programa</h1>)}
        
                    <div className='contImgYParrafosSecciones'>
                        <div className='contParrafosSecciones flex column'>
                            <h2 className="titulosExp">{(isInglish && <>Multi-age groups</>) || (!isInglish && <>Grupos multiedad</>)}</h2>
                            {(isInglish && 
                            <p> 
                                Our program is designed for children between the ages of 2 and 8 in a multi-age group setting. <br />
                                <span className='color-programa'> We believe that this approach promotes individualized learning, allowing children to develop skills at their own pace in a nurturing environment. </span> <br />
                                In multi-age groups, children benefit from a diverse range of ideas and abilities without the pressure to keep up with an entire class moving at the same level.<br/>
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Nuestro programa está diseñado para niños de entre 2 y 8 años en un grupo de edades mixtas. <br />
                                <span className='color-programa'> Creemos que este enfoque promueve el aprendizaje individualizado, permitiendo que los niños desarrollen habilidades a su propio ritmo en un entorno enriquecedor. </span> <br />  
                                En los grupos de edades mixtas, los niños se benefician de una variedad de ideas y habilidades sin la presión de seguir el ritmo de toda una clase al mismo nivel.<br/>
                            </p>)}
                        </div>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/multiage_seccion/little_creek_school_multiage_1.jpg" alt="multiage" />
                        </div>
                    </div>

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones' src="/images/multiage_seccion/little_creek_school_multiage_2.jpg" alt="multiage" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                In these groups, younger children often see older peers as mentors, learning naturally by observing and being inspired by their more advanced work. Over time, as the younger ones grow, they take on leadership roles, transitioning from observers to leaders at their own pace. <br />
                                This dynamic also strengthens relationships between families and the school, as having the same teacher for longer periods fosters a more fluid connection between home and the classroom, benefiting the child's development.<br/>
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                               En estos grupos, los niños más pequeños a menudo ven a los mayores como mentores, aprendiendo de manera natural al observar y sentirse inspirados por su trabajo más avanzado. Con el tiempo, a medida que los más pequeños crecen, asumen roles de liderazgo, haciendo la transición de observadores a líderes a su propio ritmo. <br />
                               Esta dinámica también fortalece las relaciones entre las familias y la escuela, ya que tener al mismo maestro por más tiempo fomenta una conexión más fluida entre el hogar y el aula, lo que beneficia el desarrollo del niño.<br/>
                            </p>)}
                            <img src="/images/ardilla.png" className='stickerGrupos' alt="squirrel" />
                        </div>
                    </div>            
                   
                </div>
            </div>   
            <EndNavBar/>
        </>
    );
}

export default Grupos;
