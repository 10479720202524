import React, { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';

const Filosofia = () => {
    const {isInglish} = useContext(IdiomaContext);
     
    return ( 
        <>
            <div className='contSeccion flex OoS' secciontitle={(isInglish && "The Little Creek Experience") || (!isInglish && "La experiencia Little Creek")} subsecciontitle={(isInglish && "Our Philosophy") || (!isInglish && "Nuestra Filosofía")}>
                <div className='seccion'>
                    {(isInglish && <h1 className='tituloExp'>Philosophy</h1>) || (!isInglish && <h1 className='tituloExp tituloNino'>Filosofía Educativa</h1>)}

                    <div className='contImgYParrafosSecciones reverse'>
                        <div className='contImgSecciones'>
                            <img className='imgSecciones opt' src="/images/children_seccion/little_creek_school_children_1.jpg" alt="children" />
                        </div>
                        <div className='contParrafosSecciones flex column'>
                            {(isInglish && 
                            <p> 
                                Our educational philosophy is rooted in constructivism and embraces a holistic approach to learning. We focus on nurturing the whole child—mind, body, and spirit—while fostering a love for learning, developing critical thinking and problem-solving skills, and promoting social and emotional growth. <br />
                                <span className='color-experiencia'>We strive to create a learning environment that celebrates diversity, encourages creativity, and values each child's unique strengths and abilities. </span> <br />
                                Our holistic approach incorporates activities that support physical health, emotional well-being, and cognitive development. By offering opportunities for exploration, creation, and collaboration, we prepare children to become active and engaged global citizens.
                            </p>) 
                            || 
                            (!isInglish && 
                            <p>
                                Nuestra filosofía educativa está basada en el constructivismo y adopta un enfoque holístico del aprendizaje. Nos enfocamos en apoyar al niño en su totalidad—mente, cuerpo y espíritu—fomentando el amor por el aprendizaje, desarrollando habilidades de pensamiento crítico y resolución de problemas, y promoviendo el crecimiento social y emocional a través de experiencias significativas y contextuales. <br />
                                <span className='color-experiencia'>Nos esforzamos por crear un entorno de aprendizaje que celebre la diversidad, fomente la creatividad y valore las fortalezas y habilidades únicas de cada niño. </span> <br />
                                Nuestro enfoque holístico incluye actividades que apoyan la salud física, el bienestar emocional y el desarrollo cognitivo, facilitando oportunidades para explorar, crear y colaborar. De esta manera, preparamos a los niños para convertirse en ciudadanos activos y comprometidos del mundo.
                            </p>)}
                        </div>
                    </div>
                </div>
            </div>
            <EndNavBar />
        </>
    );
}

export default Filosofia;
