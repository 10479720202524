import React from 'react';
import { useContext } from 'react';
import { IdiomaContext } from "../../../../context/IdiomaContext";
import EndNavBar from '../../../endNavBar/EndNavBar';
import ContactoAdmin from '../contactoAdmin/ContactoAdmin';
import "./horarios.css";
import { Link } from 'react-router-dom';

const Horarios = () => {

    const {isInglish} = useContext(IdiomaContext);

    return (
        <>  
            <div className='contSeccion contSeccionAdmin flex column' secciontitle={(isInglish && "Enrollment") || (!isInglish && "Administracion")} subsecciontitle={(isInglish && "School Hours and Rates") || (!isInglish && "Horarios y pagos")}>
                <div className='contTextoYContactoAdmin flex'>
                    <div className='textosAdmin flex column'>
                        {(isInglish && <h2 className="titulosExp tituloCalendario">School Hours and Rates</h2>) || (!isInglish && <h2 className="titulosExp tituloCalendario">Horarios y pagos</h2>)}
                        {(isInglish && 
                        <>
                            Our school hours are Monday - Thursday from 8:00 - 4pm. Please see our rates below. Post enrollment, we have no additional fees throughout the school year, except for school trips. <br />
                            Our monthly rates for 2024-2025 are: <br />    

                            <b>Half Day Program (8:00 – 1:00 pm)</b><br />
                            2 days (Mon, Wed) (Tue, Thu) $ 360 <br />
                            4 days (Mo, Tue, Wed, Thu) $ 720<br />
                            The half-day program includes all materials, a healthy breakfast, and a morning snack. Families provide lunch for their children. <br />
                            <b>Full Day Program (8:00 – 4:00 pm)</b><br />
                            2 days (Mon, Wed) (Tue, Thu) $ 490<br />
                            4 days (Mo, Tue, Wed, Thu) $ 980<br />
                            The full-day program includes all materials, a healthy breakfast, a morning snack, and an afternoon snack. Families provide lunch for their children. <br />
                            *Estamos registrados a través de los reembolsos de matrícula de cuidado infantil del Departamento de Servicios Humanos de Oregón (DHS).<br/>
                            For More information and to apply for financial help with child care costs, please visit:<br/> 
                            <Link to="https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx" target='_blank' className='linkAdmis'>https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx</Link>
                        </>)
                        || 
                        (!isInglish  &&             
                        <>
                            Nuestro horario escolar es de lunes a jueves de 8:00 a 4:00 p.m. Consulte nuestras tarifas a continuación. Después de la inscripción, no tenemos tarifas adicionales durante todo el año escolar, a excepción de los viajes escolares.<br/>
                            Nuestras tarifas mensuales para 2024-2025 son: <br />
                            
                            <b>Programa medio día (8:00 am – 1:00 pm)</b><br/>
                            2 días (Lun, Mie) (Mar, Jue) $ 360 <br/>
                            4 días (Lun, Mar, Mie, Jue) $ 720<br/>
                            Los programas de medio día incluyen todos los materiales, un desayuno saludable, y un snack a media mañana. Las familias deben traer el almuerzo de sus hijos. <br />
                            <b>Programa día completo (8:00 am – 4:00 pm)</b><br/>
                            2 días (Lun, Mie) (Mar, Jue) $ 490<br/>
                            4 días (Lun, Mar, Mie, Jue) $ 980<br/>
                            Los programas de día completo incluyen todos los materiales, un desayuno saludable, un snack a media mañana, y un snack a media tarde. Las familias deben traer el almuerzo de sus hijos. <br />
                            *We are registered through Oregon Department of Human Services (DHS) childcare tuition reimbursements. <br/>
                            Para obtener más información y solicitar ayuda financiera con los costos de cuidado infantil, visite: <br/>
                            <Link to="https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx" target='_blank' className='linkAdmis'>https://www.oregon.gov/DHS/assistance/CHILD-CARE/Pages/parents.aspx</Link>
                        </>)}
                    </div>
                    <div className='contContactoAdmin flex'>
                        <ContactoAdmin/>     
                    </div>
                </div>
            </div>  
            <EndNavBar />
        </>
    );
}

export default Horarios;
